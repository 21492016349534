import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ImageTwo from '../components/image'
import SEO from "../components/seo"
import "../components/layout.css"
const IndexPage = () => (
  <Layout>

    <SEO title="JRAT" />

  <div class="spacingdiv" style={{backgroundColor:"#95d7eb"}}>
<span class="a">
    <nav class="b">

        <ul>

          <li>
            <Link to="/about/">Our Team</Link>
          </li>
          <li>
          <a target="_blank" href="https://dl.acm.org/doi/pdf/10.1145/3351095.3372859">
          Paper
          </a>
          </li>
          <li><Link to="/studyguide">Study Guide</Link>
          </li>
  <li><a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTOjmDqOZyi9XxC5Hiu9uur82WEUk_IGDcWLyBgj4IibAf-WA5QPaLD1qd7kwihCwKvfHaLqiyArTxI/pub">Development</a></li>

          <li><a target="_blank" href="http://rkarkarka.pythonanywhere.com/">Demo</a></li>
          <li>
        <Link to="/casestudy/">Case Study</Link>  </li>
          <li>
    <Link to="/"> Study Up </Link>   </li>
    <li>

    </li>

        </ul>

      </nav>
      </span>
      </div>

     <div class="row" style={{backgroundColor:"#95d7eb"}}>

    <div class="column" style={{backgroundColor:"#95d7eb"}}>
<div class="imagesize">
<div class="smallImage">
      <ImageTwo file="slide1.png" />
      </div>
      </div>
      </div>
      <div class="column" style={{backgroundColor:"#95d7eb"}} >

      <div class="vertical-center2" style={{backgroundColor:"#95d7eb"}}>
  <p style={{color:"#38717d"}}><h1 align="center"><b><u>Study Up</u></b></h1>


     <h1> <b> Data scientists aspire to solve the world’s toughest problems. </b><br /> <br />
       But despite good intentions, many <b> "Data for Social Good" </b>projects are fatally flawed.
      </h1></p>
       </div></div></div>

      <div class="hasbackground">
     <div class="row" style={{backgroundColor:"#3F7FA2"}}>
     <div class="column">
     <div class="column2">
     <div
     data-sal="zoom-in"
     data-sal-delay="800"
     data-sal-easing="ease"
     >
     <p><h1>
    Data scientists tend to study and collect data from marginalized groups that bear the brunt of social ills —not the powerful groups who create the conditions for those problems to arise in the first place.
 </h1></p></div>
     <div
     data-sal="fade"
     data-sal-delay="900"
     data-sal-easing="ease">
     <p><h1>
     When techno-solutions turn a blind eye to the powerful they are doomed to reinforce the status quo.
     </h1></p>
     </div>
     </div>
     </div>

     <div class="columnimg">
     <div class="smallImage">
     <ImageTwo file = "slide2.png" />
     </div>
     </div>
     </div>
</div>
<div class="row" style={{backgroundColor:"#7D7C84"}}>
<div class="column" style={{}}>
<div class="column23">
<div class="vertical-center4">
<div
data-sal="slide-down"
data-sal-delay="200"
>
<p><h1 style={{color:"white"}}>

Computer scientists have tried to address these challenges by developing complex measuring sticks to evaluate an algorithm’s <b> "fairness." </b> But these equations often miss the point.
<br /><br />
<b> Justice requires more than just tweaking the numbers.</b>



</h1></p>
</div></div></div>
</div>
 <div class="columnimg">
 <div class="smallImage">
 <ImageTwo file="slide3.png" />
 </div>
 </div>
 </div>



 <div class="row" style={{backgroundColor:"#3F7FA2",padding:"0em 0em 4em 0em"}}>
  <div class="column">
  <div class="imagesizing">
  <div class="smallImage">
  <ImageTwo file="slide4.png" />
  </div>
  </div>
  </div>
  <div class="column" style={{}}>
  <div class="column4">
  <div class="hello">

  <p style={{color:"white"}}><h1>  It requires us to <b> “study up”</b>—to map, measure, and intervene on the ways that powerful actors maintain social inequity through their decisions and policies. <br /> <br /> We must "flip the script" to radically re-imagine the terms of our engagement with data.
  <br />
  <br />
  </h1></p>

  </div>
  </div> </div></div>
  <div class="row" style={{backgroundColor:"#644462", padding:"2em 0em 1em"}}>
  <div class="column" >
  <div class="column23">
  <div class="vertical-center4">
  <div
  data-sal="fade"
  data-sal-delay="200"
  >
  <p><h1 style={{color:"white"}}>
But studying up is hard. The owners of data often limit access to their information when we study up, because studying up implicates them in the problems that need to be solved.

  </h1></p>
  </div></div></div>
  </div>

   <div class="columnimg">
   <div class="smallImage">
   <ImageTwo file="slide5.png" />
   </div>
   </div>
   </div>
   <div class="row" style={{backgroundColor:"#3F7FA2"}}>
   <div class="column" >
   <div class="column3">
   <div
   data-sal="fade"
   data-sal-delay="200"
   >
   <p><h1 style={{color:"white"}}>
We need better strategies for engaging in the political economy of data science.
<br /> <br />
We need a <Link to="/studyguide/"><u style={{color:"blue"}}><b style={{color:"blue"}}>roadmap </b></u></Link>for studying up.
   </h1></p>
   </div></div></div>
    <div class="columnimg">
    <div class="smallImage">
    <ImageTwo file="slide6.png" />
    </div>
    </div>
    </div>
   <div align="center" style={{backgroundColor:"#3F7FA2"}}>
   <br />


<br />

<div align="center"><Link to="/"><h1><u style={{color:"blue"}}><b style={{color:"blue"}}>Jump Back to Top</b></u></h1></Link><br /><br />
</div>
</div>
<div class="hello" style={{backgroundColor:"#3F7FA2"}}>
<div style={{backgroundColor:"#3F7FA2"}} class="small" >
<ImageTwo file="mitmedia.png" />
</div>
</div>





  </Layout>

)

export default IndexPage
